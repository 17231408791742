import { render, staticRenderFns } from "./merchantApplyInfo.vue?vue&type=template&id=c672535c&scoped=true&"
import script from "./merchantApplyInfo.vue?vue&type=script&lang=js&"
export * from "./merchantApplyInfo.vue?vue&type=script&lang=js&"
import style0 from "./merchantApplyInfo.vue?vue&type=style&index=0&id=c672535c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c672535c",
  null
  
)

export default component.exports